import has from 'lodash/has';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import React from 'react';

import { Typography } from '@sravni/react-design-system';
import { useDeviceInfoContext } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import type * as Microcredits from '@src/@types/microcredits';
import { MobilePageHeading } from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading';
import { SimplePageHeaderFilters } from '@src/components/PageHeader';
import { SimplePageHeaderPhone } from '@src/components/PageHeader/SimplePageHeaderPhone';
import { Experiments } from '@src/constants/abTest';
import { AMOUNT_DEFAULT, TERM_DEFAULT } from '@src/constants/fullDeal';
import { FullDealEntryPoint } from '@src/containers/list/ProductList/FullDealEntryPoint';
import { sendAmountFilterEvent, sendTermFilterEvent } from '@src/helpers/analyticsEvents';
import { useCreditsExtraFiltersVisibility } from '@src/hooks/useCreditsExtraFiltersVisibility';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsFullDealBlockVisible } from '@src/utils/checkIsFullDealBlockVisible';
import { isNaKartuPage, isSpecialPageWithoutQuery } from '@src/utils/routing';
import { PageHeaderFilters } from 'src/components/details/components/PageHeaderFilters';

import styles from '../../styles.module.scss';

interface Props {
    filters: Microcredits.IFilters;
    difference?: number;
    hasBanner: boolean;
    seo?: Partial<Seo>;
    onChange: (value: any, name: string) => void;
}

export const PageHeaderBrandingFilters: FC<Props> = ({ filters, seo, difference, hasBanner, onChange }) => {
    const { asPath, query } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');

    const hasUtmLabel = useHasUtmLabel();
    const [conditionsActive] = useCreditsExtraFiltersVisibility();

    const { phone: isPhone } = useDeviceInfoContext();

    const { isAVariant } = useTestVariant(Experiments.FULL_DEAL_ENTRY_BLOCK);

    const isFullDealBlockVisible =
        isPhone && isAVariant && checkIsFullDealBlockVisible(pathWithoutQuery) && !hasUtmLabel;

    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isCRMSpecial = isSpecial && has(query, 'landing_type');
    const showNewFilters = isNaKartuPage(pathWithoutQuery);

    if (isFullDealBlockVisible) {
        return (
            <>
                <Typography.Heading className={styles.heading} level={1}>
                    {seo?.heading ?? 'Займы'}
                </Typography.Heading>

                <FullDealEntryPoint />

                <MobilePageHeading
                    amountChangeAnalyticEvent={() => sendAmountFilterEvent(true)}
                    termChangeAnalyticEvent={() => sendTermFilterEvent(true)}
                    initialAmount={filters.amount ?? AMOUNT_DEFAULT}
                    onChange={onChange}
                    initialTerm={filters.term ?? TERM_DEFAULT}
                />
            </>
        );
    }

    const isPhoneInputShown = (isSpecial && !isCRMSpecial) || pathWithoutQuery === '/zaimy/';

    if (isPhoneInputShown) {
        return (
            <div className={hasBanner ? undefined : 'h-mt-20'}>
                <SimplePageHeaderPhone />
            </div>
        );
    }

    if (showNewFilters) {
        return <PageHeaderFilters initialAmount={filters.amount} initialTerm={filters.term} onChange={onChange} />;
    }

    return (
        <SimplePageHeaderFilters
            filters={filters}
            difference={difference}
            conditionsActive={conditionsActive}
            onChange={onChange}
            seo={seo}
        />
    );
};
